<template>
  <div class="manage-fp-video-table">
    <!-- =========================== Filter ============================ -->
    <filter-panel
      v-model="isFilterActive"
      @clear="clearFilter"
      @filter="filterData"
    >
      <div class="row p-2">
        <fd-input
          v-model="filter['agencyUser:name']"
          class="col-12 sm-col-6 md-col-3 px-1 mb-2"
          label="Agent Name"
          name="filterAgentName"
          type="text"
          @keyup.native.enter="filterData()"
        ></fd-input>
        <fd-input
          v-model="filter['projectUnitFloorPlan:name']"
          class="col-12 sm-col-6 md-col-3 px-1 mb-2"
          label="Floor Plan Name"
          name="filterprojectUnitFloorPlan"
          type="text"
          @keyup.native.enter="filterData()"
        ></fd-input>
      </div>
    </filter-panel>
    <!-- ========================= Table Listing ========================= -->
    <div class="card">
      <vue-good-table
        mode="remote"
        @on-page-change="onPageChange"
        @on-per-page-change="onPerPageChange"
        :columns="videoTableColumns"
        :rows="videoTableData"
        :totalRows="videoTablePagination.total"
        :pagination-options="{
          enabled: true,
          mode: 'pages',
          perPage: 30,
          perPageDropdown: perPageOptions,
          dropdownAllowAll: false
        }"
        :sort-options="{
          enabled: false
        }"
        @on-row-click="openVideoDetail"
      >
        <div slot="emptystate">
          <no-data message="There is no data"></no-data>
        </div>
        <div slot="table-actions" class="p-2">
          <button
            class="btn toggle"
            :class="{ active: isPreviewActive }"
            @click="isPreviewActive = !isPreviewActive"
          >
            <i class="fas fa-film"></i>
            <span class="d-none sm-d-inline-block ml-1"> Video Preview</span>
          </button>
          <button
            :class="{ active: isFilterActive }"
            class="btn toggle ml-1"
            @click="filterToggle"
          >
            <i class="fas fa-filter"></i>
          </button>
        </div>
        <!-- Table Buttons -->
        <template slot="table-row" slot-scope="props">
          <span
            v-if="
              props.column.field == 'actions' && status == 'Pending Approval'
            "
            @click.stop
          >
            <button class="btn success" @click="approve(props.row.id)">
              <i class="fas fa-check"></i>
            </button>
            <button
              class="btn danger bordered ml-1"
              @click="reject(props.row.id)"
            >
              <i class="fas fa-times"></i>
            </button>
          </span>
          <span v-if="props.column.field == 'agent'" @click.stop>
            <div class="d-flex align-items-center">
              <img
                v-if="props.row.agencyUser.avatar[0]"
                class="avatar"
                :src="$getFileURL(props.row.agencyUser.avatar[0])"
                :alt="props.row.agencyUser.name"
                loading="lazy"
                @error="setDefaultAvatar"
              />
              <p class="d-inline-block ml-1">
                {{ props.row.agencyUser.name }}
              </p>
            </div>
          </span>
          <span v-if="props.column.field == 'video'" @click.stop>
            <a :href="props.row.videoLink">{{ props.row.videoLink }}</a>
            <video-embed
              v-if="isPreviewActive"
              css="mt-1 embed-responsive-16by9"
              :src="props.row.videoLink"
            ></video-embed>
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
    </div>
  </div>
</template>

<script>
import vueGoodTable from "@/mixins/vue-good-table/mixin";
import { setDefaultAvatar } from "@/utils/image";

export default {
  components: {},
  mixins: [vueGoodTable],
  props: {
    status: {
      type: String,
      required: true
    }
  },
  data: function () {
    return {
      tab: "",

      videoTableData: [],
      videoTablePagination: {},
      videoTableColumns: [
        {
          label: "",
          field: "actions",
          tdClass: "text-center",
          sortable: false,
          width: "150px",
          hidden: false
        },
        {
          label: "Agent",
          field: "agent"
        },
        {
          label: "Floor Plan",
          field: "projectUnitFloorPlan.name"
        },
        {
          label: "Video",
          field: "video",
          width: "350px"
        }
      ],
      tableParams: {
        page: 1,
        perPage: 30
      },
      isPreviewActive: true,

      isFilterActive: false,
      filter: {
        "agencyUser:name": "",
        "projectUnitFloorPlan:name": ""
      },

      setDefaultAvatar: setDefaultAvatar
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    async init() {
      this.videoTableColumns[0].hidden = this.status != "Pending Approval";
      await this.getAllVideos();
    },

    // ===================== Table related methods =====================
    updateParams(newProps) {
      this.tableParams = Object.assign({}, this.tableParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.getAllVideos();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.getAllVideos();
    },
    filterToggle() {
      this.isFilterActive = !this.isFilterActive;
    },
    openVideoDetail(event) {
      //
    },
    // ===================== Filter related methods =====================
    filterData() {
      this.tableParams.page = 1;
      // Trim filter
      this.filter = this._.mapValues(this.filter, (item) => item.trim());
      this.getAllVideos();
    },
    clearFilter() {
      this.tableParams.page = 1;
      this.filter = this._.mapValues(this.filter, () => "");
      this.getAllVideos();
    },

    // ============================== API RELATED ==============================
    async getAllVideos() {
      this.$store.commit("setIsLoading", true);
      try {
        let filteredParam = {
          ...this.$cleanQueryParam(this.filter),
          status: this.status
        };

        let data = await this.$store.dispatch(
          "manageFloorPlanVideos/getAllVideos",
          {
            queries: filteredParam,
            page: this.tableParams.page,
            perPage: this.tableParams.perPage
          }
        );

        this.videoTableData = this._.cloneDeep(data.data);
        this.videoTablePagination = this._.cloneDeep(data.meta.pagination);
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        throw error;
      }
    },
    async approve(id) {
      let confirm = await this.$confirm({
        title: "Approve Video",
        message: "Are you sure you want to approve this video?",
        confirmText: "Approve"
      });
      if (confirm) {
        try {
          this.$store.commit("setIsLoading", true);

          await this.$store.dispatch("manageFloorPlanVideos/approveVideo", id);
          this.$notify({
            group: "alert",
            type: "success",
            title: "Operation successful",
            text: "The video is approved."
          });
          this.getAllVideos();

          this.$store.commit("setIsLoading", false);
        } catch (error) {
          this.$store.commit("setIsLoading", false);
          this.$notify({
            group: "alert",
            type: "error",
            title: "Error",
            text: "An unexpected error occured. Please try again later."
          });
          this.$reportError(error, "Admin Approve Video");
          throw error;
        }
      }
    },
    async reject(id) {
      let confirm = await this.$confirm({
        type: "alert",
        title: "Reject Video",
        message: "Are you sure you want to reject this video?",
        confirmText: "Reject"
      });

      if (confirm) {
        try {
          this.$store.commit("setIsLoading", true);

          await this.$store.dispatch("manageFloorPlanVideos/rejectVideo", id);
          this.$notify({
            group: "alert",
            type: "success",
            title: "Operation successful",
            text: "The video is rejected."
          });
          this.getAllVideos();

          this.$store.commit("setIsLoading", false);
        } catch (error) {
          this.$store.commit("setIsLoading", false);
          this.$notify({
            group: "alert",
            type: "error",
            title: "Error",
            text: "An unexpected error occured. Please try again later."
          });
          this.$reportError(error, "Admin Approve Video");
          throw error;
        }
      }
    }
  }
};
</script>

<style lang="scss">
.manage-floor-plan-videos {
  img.avatar {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    object-fit: cover;
    display: inline-block;
  }
}
</style>
